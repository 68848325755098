<template>
  <div class="page">
    <div class="main-title">DISCLAIMER</div>
    <div class="title">免责说明</div>
    <p>
      “奉化丨家门口”小程序提醒您：在使用“奉化丨家门口”小程序（以下简称小程序）各项服务前，请您务必仔细阅读并透彻理解本声明，您可以选择不使用小程序服务，但如果您使用小程序服务的，您的使用行为将被视为对本声明全部内容的认可。
    </p>
    <p>
      用户明确同意小程序的使用由用户个人承担风险，奉化区融媒体中心明确表示不提供任何类型的担保，不论是明确的或隐含的。用户理解并接受任何信息资料，取决于用户自己并由其承担系统受损或资料丢失的所有风险和责用户明确同意小程序的使用由用户个人承担风险，奉化区融媒体中心明确表示不提供任何类型的担保，不论是明确的或隐含的。用户理解并接受任何信息资料任。
    </p>
    <p>
      所有在小程序上发布的信息均由小程序的用户提供，因此该信息的合法性和真实性由该用户和信息发布者负责。如果遇到诸如商品质量、退换货、服务瑕疵、信息虚假等争议和纠纷，您可以依照相关法律法规直接与信息发布者解决，小程序对该信息不负任何直接的、间接的法律责任。
    </p>
    <p>
      对本小程序上所有内容包括但不限于小程序运营或本小程序上的信息、内容、材料或产品，不提供明示或暗示的担保，包括但不限于为特定目的做的商务暗示担保。小程序对任何损失或任何由于使用本小程序而引起的损失，包括但不限于直接的、间接的、偶然的、惩罚性的和引发的损失，不承担责任。
    </p>
    <p>
      用户单独承担发布内容的责任，用户的一切言论必须遵守国家法律法规；不得发布危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的信息；不得发布煽动民族仇恨、民族歧视，破坏民族团结的信息；不得散布谣言，扰乱社会秩序，破坏社会稳定；不得散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的内容；不得发布侮辱及诽谤他人或者泄露别人隐私，侵害集体及他人合法权益的内容；否则一切后果由发布者本人单独承担。
    </p>
    <p>
      小程序用户卖出、买入的物品及相关信息为用户自愿提供，所提供信息均为公开信息，以供交易所用。用户在小程序中共享资源时所发生的纠纷，须由买卖双方自行解决，小程序不负任何责任。小程序会在必要时修改服务条款，服务条款一旦发生变动，用户须遵守新的服务条款。小程序保留，修改或中断服务而无需通知用户的权利。小程序行使，修改或中断服务的权利，不需对用户或第三方负责。
    </p>
    <p>
      本法律声明要与中华人民共和国的法律解释相一致，用户和小程序一致同意服从中华人民共和国人民法院管辖。如与中华人民共和国法律相抵触时，则这些条款将完全按照法律规定重新解释，而其他条款则依旧具有法律效力。我们保留随时更改上述免责及其他条款的权利。
    </p>
    <div class="name">奉化区融媒体中心</div>
    <div class="time">2022年11月29日</div>
  </div>
</template>

<script>
export default {
  name: "disclaimers",
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 50px 30px 100px 30px;
  .name,
  .time {
    text-align: right;
  }
  .name,
  .time,
  p {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
  }
  .title {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-bottom: 32px;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
    margin-bottom: 56px;
  }
  .main-title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    padding-bottom: 8px;
  }
}
</style>